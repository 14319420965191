import styled from 'styled-components'
import Accordion from 'react-bootstrap/Accordion'

const StyledAccordion = styled(Accordion)`
  //  No media query for extra small devices (portrait phones, less than 576px)
  .card {
    background-color: #dbb2624f !important; // rgb(219 178 98 / 31%)
    color: #0e4040 !important;
    font-weight: bold;
    font-size: 1.1rem;
    border-radius: 1.25rem !important;
  }
  .card.outer {
    border-radius: 1rem !important;
  }
  .card.inner {
    margin-bottom: 0.8rem;
  }
  .card-header {
    border-radius: 1rem !important;
  }
  // Small devices (landscape phones, 576px and up, col-sm)
  @media (min-width: 576px) {
  }
  // Medium devices (tablets, 768px and up, col-md)
  @media (min-width: 768px) {
  }
  // Large devices (desktops, 992px and up, col-lg)
  @media (min-width: 992px) {
      
      .card-header {
          h4 {
              font-size: 1.2rem;
          }

          p {
              font-size: 1rem;
          }
      }

      .card-body {
          color: var(--page-text-color);
          font-size: 1.2rem;

          p {
              font-size: 1rem;
          }
      }
  }
  // Extra large devices (large desktops, 1200px and up, col-xl)
  @media (min-width: 1200px) {
  }
`

export { StyledAccordion }